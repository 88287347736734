import { type PlanId } from '__generated__/GQL';

import { type PlanChangeSuccessDialogFragment } from '../../overview.gql';

/**
 * Returns metadata about a plan change
 */
export const getPlanChangeData = (
  switchedFromPlan: PlanId,
  { currentPlan, subscriptionPlan }: PlanChangeSuccessDialogFragment,
) => {
  if (!subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  const plans = [
    ...currentPlan.downgradablePlans,
    currentPlan,
    ...currentPlan.upgradablePlans,
  ];

  const getPlan = (planId: PlanId) => {
    const plan = plans.find((p) => p.id === planId);

    if (!plan) {
      throw new Error(`Plan ${planId} not found`);
    }

    return plan;
  };

  /**
   * Handles a downgrade.
   * The only way to know the target plan is to check the `scheduledDowngrade` field (all other endpoints continue to return the pre-downgrade plan).
   */
  if (subscriptionPlan.scheduledDowngrade) {
    return {
      isDowngrade: true,
      nextPlan: getPlan(subscriptionPlan.scheduledDowngrade as PlanId),
      previousPlan: getPlan(currentPlan.id),
    };
  }

  /**
   * This case is relatively hypothetical: the user just downgraded, but it was not marked a a "scheduledDowngrade".
   * Maybe if the user downgrades on the day of the billing cycle, the downgrade is immediate, so we've handled it here just in case.
   */
  if (
    currentPlan.upgradablePlans.find((plan) => plan.id === switchedFromPlan)
  ) {
    return {
      isDowngrade: true,
      nextPlan: getPlan(currentPlan.id),
      previousPlan: getPlan(switchedFromPlan),
    };
  }

  /**
   * Handles an upgrade.
   */
  if (
    currentPlan.downgradablePlans.find((plan) => plan.id === switchedFromPlan)
  ) {
    const nextPlan = getPlan(currentPlan.id);
    const previousPlan = getPlan(switchedFromPlan);

    return {
      hasUnlockedNewPhysicalCards:
        nextPlan.paymentCard.type !== previousPlan.paymentCard.type ||
        (previousPlan.limits.physicalPaymentCard.value === 0 &&
          nextPlan.limits.physicalPaymentCard.value > 0),
      isDowngrade: false,
      nextPlan,
      previousPlan,
    };
  }

  return null;
};
