import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Flex, type FlexProps, Image } from '@chakra-ui/react';
import { Icon, Squircle, Typography } from '@shinetools/sunshine-universal';

import wallet from 'assets/brand/wallet.png';
import Button from 'components/_core/Button';
import Skeleton from 'components/Skeleton';
import SkeletonText from 'components/SkeletonText';
import { formatDuration } from 'helpers/date';

import { PLANS_CARD_IMAGES } from '../../../../lib/cardImages';
import * as routes from '../../../../routes';
import { getTrialPeriod } from '../../helpers/getTrialPeriod';
import type * as GQL from '../../overview.gql';
import { Body } from './components/Body';
import { Container } from './components/Container';
import { Footer } from './components/Footer';
import { FooterItem } from './components/FooterItem';

export type OverviewHeaderProps = FlexProps & {
  data?: GQL.SubscriptionManagementOverviewQuery;
};

export const OverviewHeader: FC<OverviewHeaderProps> = (props) => {
  const { data, ...rest } = props;

  const isLoaded = Boolean(data);
  const currentPlan = data?.viewer.company.currentPlan;
  const trialPeriod = getTrialPeriod(data);

  return (
    <Container {...rest}>
      <Body>
        <Flex direction="column" gap="space-12">
          <Flex align="center" gap="space-12">
            {currentPlan?.id ? (
              <Typography.Header size="large">
                <FormattedMessage
                  id="subscription.overview.header.plan"
                  values={{
                    plan: currentPlan.brandName,
                  }}
                />
              </Typography.Header>
            ) : (
              <SkeletonText minWidth="space-120" skeletonHeight="36" />
            )}

            {currentPlan?.id ? (
              <Image src={PLANS_CARD_IMAGES[currentPlan.id]} width="space-40" />
            ) : (
              <SkeletonText minWidth="space-40" skeletonHeight="36" />
            )}
          </Flex>

          {trialPeriod.isOngoing ? (
            <Flex align="center" gap="space-6">
              <Icon color="$grey.800" icon="sparkles" size="small" />

              <Typography.Text bold>
                <FormattedMessage
                  id="subscription.overview.header.end_of_trial_period"
                  values={{
                    duration: formatDuration(trialPeriod.duration, {
                      format: ['years', 'months', 'days'],
                    }),
                  }}
                />
              </Typography.Text>
            </Flex>
          ) : null}
        </Flex>

        {isLoaded ? (
          <Button as={Link} to={routes.plans}>
            <FormattedMessage id="subscription.overview.header.compare_offers" />
          </Button>
        ) : (
          <Skeleton height="space-40" width="space-128" />
        )}
      </Body>
      <Footer>
        <FooterItem
          borderBottomLeftRadius="radius-8"
          borderRight="1px solid"
          borderRightColor="grey.300"
        >
          <Squircle backgroundColor="$grey.200" icon="calendar" />

          <Flex direction="column">
            <Typography.Text bold>
              <FormattedMessage id="subscription.overview.header.billing.label" />
            </Typography.Text>

            {currentPlan ? (
              <Typography.Text size="small" variant="secondary">
                <FormattedMessage id="subscription.overview.header.billing.value" />
              </Typography.Text>
            ) : (
              <SkeletonText skeletonHeight="20" />
            )}
          </Flex>
        </FooterItem>

        <FooterItem borderBottomRightRadius="radius-8">
          <Image
            backgroundColor="grey.200"
            borderRadius="radius-8"
            boxSize="space-32"
            src={wallet}
          />

          <Flex direction="column">
            <Typography.Text bold>
              <FormattedMessage id="subscription.overview.header.payment_method.label" />
            </Typography.Text>

            {currentPlan ? (
              <Typography.Text size="small" variant="secondary">
                <FormattedMessage id="subscription.overview.header.payment_method.value" />
              </Typography.Text>
            ) : (
              <SkeletonText skeletonHeight="20" />
            )}
          </Flex>
        </FooterItem>
      </Footer>
    </Container>
  );
};
